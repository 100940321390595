.tile {
  &.filled {
    background-color: $color-sono-sun;
  }

  border: 2px solid rgba(251, 186, 0, 0.7);
  border-radius: 8px;
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: space-between;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 6px, rgba(251, 186, 0, 0.2) 0px 3px 6px;

  .timestamp {
    font-size: 11px;
    font-weight: normal;
  }

  .number {
    font-size: 20px;
    .unit {
      margin-left: 2px;
      font-size: 12px;
    }
  }
}
