.operationalDetails {
  @include flex-container(column);
  padding-bottom: 10px;

  h2 {
    margin: 5px 0;
    font-size: $font-size-lg;
    border: none;
    text-transform: none;
    padding: 0;
  }

  .month {
    @include flex-container(row, space-between, center);
    @include font-style($font-size-lg, bold);

    button {
      font-size: $font-size-lg;
    }
  }

  .days {
    @include flex-container(row, stretch, center, wrap);
    border-bottom: 1px solid $color-sono-day-50;
    padding-bottom: 20px;

    @media (max-width: $responsive-xxl-break) {
      padding-bottom: 5px;
    }

    > div {
      position: relative;
      margin: 2px 0;
      width: 14%;

      @include flex-container(row, space-around, center);

      &.day {
        cursor: pointer;
      }

      &.markLeft::before {
        content: '';
        background-color: $color-sono-day-10;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 50%;
      }

      &.markRight::after {
        content: '';
        background-color: $color-sono-day-10;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 50%;
      }

      span.number {
        @include flex-container(row, space-around, center);
        @include size(24px, 24px);
        padding: 3px;
        z-index: 100;
      }

      &.selected span.number {
        color: $color-base;
        border-radius: 50%;
        background-color: $color-sono-night;
      }

      span.mode {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1px;

        @include size(3px, 3px);
        border-radius: 50%;
        z-index: 200;
      }

      .mode.inService {
        background-color: $color-sono-sun;
      }

      .mode.outService {
        background-color: $color-outservice-grey;
      }
    }
  }
}
