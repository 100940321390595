.menuBar {
  display: none;
  padding: 20px;

  @media (max-width: $responsive-lg-break) {
    @include flex-container(row, space-between, center);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-sono-night;
    color: $color-base;

    &.open > img {
      filter: blur(3px);
    }

    &.open > button > .hamburger > .hamburgerLine:nth-child(1) {
      transition: $transition;
      transform: translateY(6px) rotate(-45deg);
      width: 20px;
    }

    &.open > button > .hamburger > .hamburgerLine:nth-child(2) {
      transition: $transition;
      opacity: 0;
    }

    &.open > button > .hamburger > .hamburgerLine:nth-child(3) {
      transition: $transition;
      transform: translateY(-6px) rotate(45deg);
      width: 20px;
    }

    button {
      display: flex;
      align-content: baseline;
      color: $color-base;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      background-color: transparent;
      white-space: nowrap;
      padding: 0;
      border: transparent;

      .hamburger {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 3px;
        width: 18px;
        padding-bottom: 3px;
        transition: all 2s ease-in-out;
      }

      .hamburgerLine {
        display: block;
        background-color: $color-base;
        height: 3px;
        border-radius: 5px;
        transition: $transition;
      }

      p.current.open {
        border-bottom: 2px solid transparent;
      }
      .current {
        display: block;
        opacity: 1;
        margin: 0;
        font-size: $font-size-lg;
        border-bottom: 2px solid $color-sono-sun;
        padding-bottom: 3px;
        margin-left: 10px;

        @media (max-width: $responsive-xs-break) {
          display: none;
          opacity: 0;
        }
      }
    }

    .menuBar > button:active,
    button:hover,
    button:focus {
      color: $color-base;
    }

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @include size(30px, 30px);
    }
  }
}

.menu {
  grid-area: menu;
  max-width: 260px;
  padding-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  transition: $transition;
  transform: translateX(0);
  @media (max-width: $responsive-lg-break) {
    padding-top: 80px;
    &.closed {
      opacity: 0;
      transform: translateX(-100%);
    }

    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 990;

    img {
      display: none;
    }
  }

  @media (max-width: $responsive-sm-break) {
    max-width: 170px;
    padding-right: 15px;
  }

  display: flex;
  flex-direction: column;
  border-radius: 0;
  -webkit-background-color: $color-sono-night;
  background-color: $color-sono-night;
  -webkit-color: $color-base;
  color: $color-base;

  input[type='radio']:after {
    @media (max-width: $responsive-sm-break) {
      font-size: $font-size-sm;
    }
  }

  ul {
    @include font-style($font-size-lg, bold);
    flex-grow: 1;
  }

  li {
    padding: 0px;
    margin-bottom: 30px;
    cursor: pointer;

    @media (max-width: $responsive-xs-break) {
      margin-bottom: 24px;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.active {
      a {
        padding-bottom: 5px;
        border-bottom: 3px solid $color-sono-sun;
      }
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: $color-base;
    }
  }

  img {
    width: 208px;
    margin: 24px auto 24px 0;
  }

  .clientLabel {
    @include font-style($font-size-xl, bold);
    margin-bottom: 15px;
  }

  .logout {
    border-top: $border;
    padding: 27px 0 0 0;
    @include flex-container(column, center);

    .companyName,
    .userName {
      margin-bottom: 32px;
      width: 100%;
      text-align: center;

      @media (max-width: $responsive-sm-break) {
        text-align: center;
        word-wrap: break-word;
      }
    }

    .companyName {
      text-transform: uppercase;
      font-size: $font-size-xl;
      font-weight: bold;
    }

    a {
      padding: 15px 24px 15px 80px;

      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;

      color: $color-base;

      border: $border;
      border-radius: 32px;

      background-image: url(../img/logoutIcon.svg);
      background-repeat: no-repeat;
      background-position: 50px center;
      background-size: 24px;

      @media (max-width: $responsive-sm-break) {
        padding: 10px 10px 10px 50px;
        background-position: 20px center;
      }
    }
  }
}

.client {
  border-top: $border;
  padding: 24px 0 24px 0;

  label {
    display: block;
    @include font-style($font-size-xl, bold);
    margin-bottom: 15px;
  }
}

ul.list.fleetList {
  max-height: 150px;

  li {
    margin-bottom: 0;
  }

  label {
    font-size: $font-size-lg;
    @media (max-width: $responsive-sm-break) {
      font-size: $font-size-sm;
    }
  }
}
