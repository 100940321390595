.aggregate {
  @include flex-container(row, center, center);
  gap: 10px;
  text-align: center;

  @media (max-width: $responsive-lg-break) {
    gap: 6px;
  }

  img {
    @include size(50px, 50px);

    @media (max-width: $responsive-lg-break) {
      @include size(25px, 25px);
    }
  }

  .value {
    @include font-style(24px, bold);

    @media (max-width: $responsive-lg-break) {
      font-size: 16px;
    }
  }

  .label {
    font-size: 16px;
    text-align: center;

    @media (max-width: $responsive-sm-break) {
      font-size: 10px;
    }
  }
}
