.faqs {
  padding-bottom: 10px;
  .header {
    border-radius: 10px 10px 0 0;
    width: 100%;
    aspect-ratio: 8 / 2.2;
    background-image: url(../../img/faqs.jpg);
    background-size: cover;
    background-position: center;
    @include flex-container(row, center, center);

    @media (max-width: $responsive-lg-break) {
      aspect-ratio: 8 / 3;
    }

    @media (max-width: $responsive-sm-break) {
      aspect-ratio: 8 / 3.5;
    }

    h1 {
      border-radius: 10px 10px 0 0;
      color: $color-base;
      font-size: 46px;

      @media (max-width: $responsive-lg-break) {
        font-size: 32px;
      }

      @media (max-width: $responsive-sm-break) {
        font-size: 16px;
      }
    }
  }

  .intro {
    padding: 24px 12px;
    background-color: $color-sono-day-50;
    border-radius: 0 0 10px 10px;

    @media (max-width: $responsive-lg-break) {
      background-color: $color-base;
      text-align: center;
    }
  }

  .faqGroupList {
    @media (min-width: $responsive-sm-break) {
      margin-top: 20px;
      max-height: 240px;
      overflow: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }
  }

  .faqGroupList:hover {
    overflow: auto;
    scrollbar-color: $color-sono-day-10 transparent;
  }

  .faqGroup {
    h2 {
      text-align: center;
      position: relative;
      text-wrap: wrap;

      button.edit.addQuestion {
        font-size: 24px;
        position: absolute;
        right: 6px;
      }

      button.edit.editGroup {
        font-size: 18px;
        position: absolute;
        right: 30px;
      }
    }

    .questionsList {
      margin: 0;
      padding-right: 6px;
      margin-top: 10px;

      @media (max-width: $responsive-lg-break) {
        padding-right: 0;
      }
    }

    dt {
      cursor: pointer;
      background-color: $color-sono-day-50;
      padding: 10px;
      border-radius: 10px;
      margin-top: 12px;
      position: relative;
      @include flex-container(row, space-between, center);
      transition: all 0.2s ease-in-out;

      .arrow-icon {
        background-color: transparent;
        height: 16px;
        width: 16px;
        position: relative;
        top: 5px;
        right: 15px;
        cursor: pointer;
        margin: 0;
      }

      .left-bar {
        position: absolute;
        background-color: $color-sono-night;
        top: 2px;
        left: 0px;
        width: 18px;
        height: 2px;
        display: block;
        float: right;
        border-radius: 6px 10px 10px 6px;
        transition: all 0.2s ease-in-out;
        transform: rotate(35deg);
      }

      .right-bar {
        position: absolute;
        background-color: $color-sono-night;
        top: 2px;
        left: 14px;
        width: 18px;
        height: 2px;
        display: block;
        float: right;
        border-radius: 10px 6px 6px 10px;
        transition: all 0.2s ease-in-out;
        transform: rotate(-35deg);
      }

      .open {
        .left-bar {
          transform-origin: center center;
          transform: rotate(-35deg);
          background-color: $color-base;
        }
        .right-bar {
          transform-origin: center center;
          transform: rotate(35deg);
          background-color: $color-base;
        }
      }

      button.edit {
        font-size: 18px;
        position: absolute;
        top: -10px;
        right: 30px;
      }

      .question {
        padding-right: 20px;
      }
    }

    dt.open {
      background-color: $color-sono-night;
      color: $color-base;
      border-radius: 10px 10px 0 0;

      button.edit {
        color: $color-base;
      }
    }

    dd {
      background-color: $color-sono-day-50;
      padding: 10px;
      border-radius: 0 0 10px 10px;
      margin: 0;

      p {
        padding: 10px 0;
      }
    }
  }

  .faqGroup:not(:first-child) {
    margin-top: 10px;
  }

  button.edit {
    border: none;
    padding: 0;
    opacity: 0;
    width: auto;
    min-width: 0;
    transition: 500ms;
  }

  button.edit:hover {
    background-color: transparent;
    color: $color-sono-night;
  }

  *:hover > button.edit {
    opacity: 1;
  }

  .addGroup {
    text-align: center;

    button {
      font-size: 24px;
    }
  }
}
