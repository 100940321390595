.fallbackCard {
  padding: 18px 30px;
  @include flex-container(column, center, center);
  gap: 10px;

  @media (max-width: $responsive-lg-break) {
    padding: 16px;
  }

  border-radius: $border-radius-card;
  background-color: $color-base;
  box-shadow: 0px 4px 10px 1px rgb(186 183 177 / 40%);

  h2 {
    margin-top: 0;
  }

  &.full-width {
    flex-grow: 1;
  }
}
