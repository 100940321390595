.warning {
  font-size: $font-size-sm;
  text-align: end;
  margin: 0;

  @media (min-width: 1920px) {
    margin-top: 20px;
  }

  @media (max-width: 1919px) {
    margin-top: 10px;
  }

  @media (max-width: $responsive-sm-break) {
    font-size: 6px;
  }
}

.warning.error {
  color: red;
  text-align: start;
  margin: 0;
}

.warning.empty {
  color: red;
  text-align: center;
  margin: 0;
}

.chartContainer {
  height: 30vh;
  max-width: 940px;
  margin-inline: auto;
  margin-bottom: 12px;

  @media (max-width: $responsive-xxl-break) {
    max-width: 800px;
  }

  @media (max-width: $responsive-lg-break) {
    max-width: 680px;
    height: 320px;
  }

  @media (max-width: $responsive-xs-break) {
    margin-left: -15px;
    max-width: 360px;
    height: 220px;
  }

  @media (max-width: 390px) {
    max-width: 340px;
    height: 220px;
  }
}

.topBar {
  margin-bottom: 12px;

  @media (min-width: $responsive-sm-break) {
    @include flex-container(row, space-between, center);
  }
}

.summary {
  padding-bottom: 8px;

  .tiles {
    display: grid;
    grid-template-columns: repeat(3, minmax(112px, 160px));
    grid-template-rows: repeat(2, minmax(52px, 80px));
    grid-template-areas: 'tile1 tile2 tile3' 'tile4 tile5 tile6';
    justify-content: center;
    column-gap: 16px;
    row-gap: 8px;

    @media (max-width: $responsive-lg-break) {
      grid-template-columns: repeat(3, minmax(100px, 160px));
      grid-template-rows: repeat(2, minmax(100px, 1fr));
      gap: 10px;
      margin-top: 20px;
    }

    @media (max-width: $responsive-sm-break) {
      grid-template-columns: repeat(2, minmax(112px, 160px));
      grid-template-rows: repeat(3, minmax(52px, 90px));
      gap: 10px;
      grid-template-areas:
        'tile1 tile2'
        'tile3 tile4'
        'tile5 tile6';
    }

    .tile1 {
      grid-area: tile1;
    }
    .tile2 {
      grid-area: tile2;
    }
    .tile3 {
      grid-area: tile3;
      @media (max-width: $responsive-sm-break) {
        grid-area: tile5;
      }
    }
    .tile4 {
      grid-area: tile4;
      @media (max-width: $responsive-sm-break) {
        grid-area: tile3;
      }
    }
    .tile5 {
      grid-area: tile5;

      @media (max-width: $responsive-sm-break) {
        grid-area: tile4;
      }
    }
    .tile6 {
      grid-area: tile6;
    }
  }
}
