input.searchInput {
  padding-right: 10px;
  background-image: url('../../img/searchIcon.svg') !important;
  background-size: 16px 16px;
  background-repeat: no-repeat !important;
  background-position: right !important;
  border-right: 12px solid transparent !important;
}

.sort {
  position: relative;
}

.sortOptionsList {
  position: absolute;
  z-index: 800;
}

.viewAllLink,
.viewAllLink:visited {
  width: 100%;
  display: block;
  @include font-style($font-size-md, bold);
  text-align: center;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  text-transform: uppercase;
  color: $color-sono-night;
  border-top: $border-color;

  &:hover,
  &:focus {
    color: $color-sono-sun;
  }
}

input.docSearch {
  @media (min-width: $responsive-md-break) {
    width: 50% !important;
  }
}
