g > text > tspan {
  font-size: 10px;
  font-weight: normal;

  @media (max-width: $responsive-md-break) {
    font-size: 6px;
  }
}

g > g > text > tspan {
  font-size: 10px;
  font-weight: normal;
}

g:nth-child(odd) > text > tspan,
g:nth-child(odd) > g > text > tspan {
  font-weight: 700;
}
