.details {
  padding-bottom: 10px;
  @media (max-width: $responsive-sm-break) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .vehicleSelector {
    display: grid;
    grid-template-columns:
      minmax(max-content, 50px)
      minmax(max-content, 280px);
    column-gap: 6px;
    align-items: center;

    details {
      width: auto;
      min-width: 30%;
      font-family: $font-family;
    }

    .spacer {
      flex-grow: 1;
    }

    @media (max-width: $responsive-sm-break) {
      justify-content: center;
      padding: 0 16px;
    }
  }

  @media (max-width: $responsive-sm-break) {
    padding-left: 0;
    padding-right: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    column-gap: 5px;
    justify-content: center;

    @media (max-width: $responsive-md-break) {
      column-gap: 10px;
    }

    @media (max-width: $responsive-sm-break) {
      display: block;
      width: 100%;
    }

    li {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (min-width: 1367px) and (max-width: 1680px) {
        grid-template-columns: 1.6fr 1fr;
      }

      @media (max-width: $responsive-lg-break) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $responsive-md-break) {
        grid-template-columns: 1.25fr 1fr;
      }

      @media (max-width: $responsive-sm-break) {
        grid-template-columns: 0.75fr 1fr;
      }

      @media (min-width: $responsive-sm-break) {
        &:nth-child(-n + 3) {
          background-color: $color-backlightgray;
        }
      }

      @media (max-width: $responsive-sm-break) {
        padding-left: 16px;

        &:nth-child(odd) {
          background-color: $color-backlightgray;
        }
      }
    }

    span {
      display: block;
      padding: 8px 4px 8px 8px;

      @media (min-width: 1919px) {
        font-size: 14px;
      }

      @media (max-width: $responsive-xxl-break) {
        font-size: 11px;
        padding-right: 0;
      }

      @media (max-width: $responsive-xl-break) {
        font-size: 10px;
      }

      @media (max-width: $responsive-lg-break) {
        font-size: 12px;
      }

      @media (max-width: $responsive-md-break) {
        font-size: 11px;
      }

      &:nth-child(odd) {
        font-weight: bold;
        border-right: 1px solid $color-sono-day-10;
      }
    }
  }
}
