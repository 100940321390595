.weather {
  position: absolute;
  top: -20px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  @media (max-width: $responsive-xxl-break) {
    top: -6px;
    right: 10px;
  }

  @media (max-width: $responsive-md-break) {
    top: -10px;
    right: -10px;
  }

  @media (max-width: $responsive-sm-break) {
    top: -6px;
    right: 4px;
  }

  img {
    width: 100px;
    height: 100px;
    filter: brightness(70%);

    @media (max-width: $responsive-xxl-break) {
      width: 72px;
      height: 72px;
    }

    @media (max-width: $responsive-md-break) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: $responsive-sm-break) {
      width: 60px;
      height: 60px;
    }
  }

  .temperature {
    font-weight: bold;
    margin-top: -18px;
    font-size: 14px;

    @media (max-width: $responsive-md-break) {
      margin-top: -20px;
      font-size: 16px;
    }

    @media (max-width: $responsive-sm-break) {
      margin-top: -10px;
      font-size: 14px;
    }
  }

  .description {
    font-size: 12px;

    @media (max-width: $responsive-xxl-break) {
      font-size: 12px;
    }

    @media (max-width: $responsive-md-break) {
      font-size: 10px;
    }
  }
}
