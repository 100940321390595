.tabs {
  display: flex;
  flex-direction: row;
  gap: 6px;
  border-bottom: 2px solid $color-darkblue;

  @media (max-width: $responsive-sm-break) {
    gap: 4px;
  }

  .tab {
    cursor: pointer;
    border-width: 2px 2px 0 2px;
    border-color: $color-darkblue;
    border-style: solid;
    border-radius: 10px 10px 0 0;
    padding: 9px 12px;
    font-size: $font-size-lg;

    @media (max-width: $responsive-sm-break) {
      font-size: $font-size-md;
      padding: 6px;
      word-break: normal;
    }
  }
  .tab.disabled {
    display: none;
  }

  .tab.active {
    background-color: $color-darkblue;
    color: $color-base;
  }
}
