footer {
  width: 100%;
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 45px;
  padding-bottom: 24px;

  @media (max-width: $responsive-lg-break) {
    flex-direction: column-reverse;
    align-self: center;
    margin-top: 20px;
    text-align: center;
    align-items: initial;
    padding-right: 0;
    padding-left: 0;
  }

  @media (max-width: $responsive-sm-break) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  p {
    color: $color-text-button;
    font-size: $font-size-sm;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(5, minmax(auto, auto));
    column-gap: 24px;
    padding: 0;

    @media (max-width: $responsive-lg-break) and (min-width: $responsive-sm-break) {
      margin-bottom: 8px;
    }

    @media (max-width: $responsive-sm-break) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 8px;
      margin-bottom: 8px;
    }

    li {
      display: inline;
      white-space: nowrap;

      @media (max-width: $responsive-sm-break) {
        width: max-content;
      }

      a {
        padding: 0;
        text-decoration: none;
        color: $color-text-button;
        text-transform: none;
        text-align: center;
        white-space: nowrap;
        font-size: $font-size-lg;
        @include transition(color, 0.2s, ease-in);

        @media (min-width: 1366px) and (max-width: 1439px) {
          font-size: $font-size-sm;
        }

        @media (max-width: $responsive-xl-break) and (min-width: $responsive-sm-break) {
          font-size: $font-size-md;
        }

        @media (max-width: $responsive-sm-break) {
          font-size: $font-size-md;
        }
      }

      a:hover,
      a:focus {
        color: $gallery-theme;
        border: none;
      }
    }
  }
}
