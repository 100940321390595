.vehicleDetails {
  .column {
    width: 100%;
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 2fr 1fr;
  }

  .dateTitle {
    width: 50%;
  }
}
