.cards {
  display: grid;
  gap: 10px;
}

.card {
  width: 100%;
  border-radius: $border-radius-card;
  background-color: $color-base;
  box-shadow: 0px 0px 26px 0px $color-border-shadow;

  @media (max-width: $responsive-xl-break) {
    padding-bottom: 4px;
  }

  @media (max-width: $responsive-md-break) {
    padding-bottom: 10px;
  }

  .titlePopup {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .cardContents {
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 2px solid $color-sono-sun;

    @media (max-width: $responsive-lg-break) {
      padding-top: 12px;
      padding-right: 12px;
      padding-left: 12px;
    }

    @media (max-width: $responsive-sm-break) {
      padding-top: 10px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  .cardContents.noPadding {
    padding: 0;
  }
}

.cardTitle {
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 10px;
  inline-size: 100%;
  text-wrap: wrap;

  @media (max-width: $responsive-lg-break) {
    padding: 12px;
  }

  @media (max-width: $responsive-sm-break) {
    padding: 10px;
  }
}
