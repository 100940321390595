$color-base: rgb(255, 255, 255);
$color-superlightgrey: rgb(238, 239, 240);
$color-backlightgray: rgba(228, 234, 240, 0.25);
$color-lightgrey: rgb(192, 197, 201);
$color-grey: rgb(153, 162, 167);
$color-outservice-grey: rgb(229, 229, 229);
$color-border-shadow: rgba(0, 0, 0, 0.1);
$color-text-button: rgb(186, 183, 177);
$color-modal-bg: rgb(246, 245, 241);

$color-sono-stop: rgb(213, 61, 32);
$color-sono-wait: rgb(245, 216, 0);
$color-sono-go: rgb(69, 130, 89);

$color-darkblue: rgb(0, 22, 36);
$color-blue: rgb(5, 73, 100);

$color-sono-sun: rgb(251, 186, 0);
$color-sono-sun-50: rgb(235, 172, 0);
$color-sono-day-10: rgb(186, 187, 188);
$color-sono-day-50: rgb(242, 243, 244);
$color-sono-day-90: rgb(248, 249, 251);
$color-sono-sand: rgb(239, 234, 228);
$color-sono-night: rgb(0, 22, 36);
$color-sono-wet-sand: rgb(199, 188, 168);

$gallery-theme: rgb(0, 0, 0);

$border-radius: 3px;
$border-radius-card: 8px;
$border: 2px solid $color-base;
$border-color: 1px solid $color-sono-night;

$font-name: 'Roboto Sans';
$font-family: 'Roboto Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Ubuntu, Cantarell,
  'Open Sans', Arial, sans-serif;

// Variables for font sizes
$font-size-xs: 8px;
$font-size-sm: 10px;
$font-size-md: 12px;
$font-size-lg: 14px;
$font-size-xl: 16px;

$font-margin-top: 0.1;
$font-margin-bottom: 0.05;

$transition: all 0.4s ease-in-out;

// Variables for breakpoints
$responsive-xxxl-break: 2339px;
$responsive-xxl-break: 1679px;
$responsive-xl-break: 1279px;
$responsive-lg-break: 1199px;
$responsive-md-break: 1023px;
$responsive-sm-break: 767px;
$responsive-xs-break: 480px;

// Variables for spacing
$spacing-xs: 6px;
$spacing-sm: 10px;
$spacing-md: 14px;
$spacing-lg: 16px;
$spacing-xl: 20px;

%font-gap-extra-large {
  margin-top: calc(1vw + 0.5vh + 23px);
}

%font-gap-large {
  margin-top: 1.8rem;
}

%font-gap-medium {
  margin-top: 1.4rem;
}

%font-gap-small {
  margin-top: 1.2rem;
}

%margin-top {
  margin-top: calc(0.5vw + 0.5vh + 23px);
}

%margin-bottom {
  margin-bottom: calc(0.5vw + 0.5vh + 23px);
}
