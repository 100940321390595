.hourlyOutput {
  .dayPicker {
    position: relative;
    display: grid;
    grid-template-columns: minmax(max-content, 60px) minmax(max-content, 220px);
    align-items: center;
    column-gap: 10px;
    margin-bottom: 12px;
    cursor: pointer;

    input {
      background-image: url(../../img/calendar.svg);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 1rem;
      &:hover,
      &:focus {
        background-color: $color-outservice-grey;
        @include border-style(2px, solid, $color-outservice-grey);
      }
    }
    .react-calendar {
      position: absolute;
      z-index: 999;
      top: 3rem;
      left: 1rem;
      width: 320px;
      font-family: $font-family;
      padding: 0.25rem;
      border-radius: 0.5rem;
      background-color: $color-base;
    }

    .react-calendar__month-view__days__day--weekend {
      color: black;
    }

    .react-calendar__tile--active,
    .react-calendar__month-view__days__day--weekend--active {
      background: $color-darkblue;
      color: $color-base;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus,
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background: $color-darkblue;
      color: $color-base;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus,
    .react-calendar__month-view__days__day--weekend--active:enabled:hover,
    .react-calendar__month-view__days__day--weekend--active:enabled:focus {
      background: $color-sono-sun;
    }

    .react-calendar__tile--now {
      background: $color-superlightgrey;
    }

    abbr[title] {
      text-decoration: none;
    }
  }

  .chart {
    position: relative;
    @include size(490px, 280px);
    background-color: rgb(0, 0, 0, 0);

    @media (max-width: $responsive-xxl-break) {
      @include size(380px, 220px);
      max-width: 400px;
    }

    @media (max-width: $responsive-lg-break) {
      @include size(100%, 360px);
      margin-left: -10px;
      max-width: 100%;
    }

    @media (max-width: $responsive-sm-break) {
      height: 280px;
      max-height: 400px;
    }

    @media (max-width: 380px) {
      max-width: 320px;
    }
  }
}
