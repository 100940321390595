.modal {
  &.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-modal-bg;
    opacity: 0.8;
    z-index: 10000;
    cursor: not-allowed;
  }

  &.dialog {
    position: fixed;
    z-index: 10001;
    background-color: $color-base;
    background: $color-base;
    border-radius: 30px;
    width: 650px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    @media (max-width: $responsive-lg-break) {
      width: 90vw;
    }

    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $responsive-sm-break) {
      padding: 20px;
    }

    &.centered {
      text-align: center;
    }

    h2 {
      overflow-wrap: normal;
      margin-top: 0;
      margin-bottom: 14px;
      @include font-style(21px, bold);

      @media (max-width: $responsive-md-break) {
        @include font-style(16px, bold);
      }
    }

    p {
      color: red;
    }

    &.dialog__delete {
      width: 80%;
    }
  }
}

.editDialog {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;

  .window__delete {
    text-align: center;
  }
  p {
    text-align: center;
  }
}

label {
  @include font-style($font-size-lg, bold);
}

input:not([type='checkbox']):not([type='radio']) {
  font-size: $font-size-lg;
  width: 100%;
}
