.durationPicker {
  display: grid;
  grid-template-columns:
    minmax(60px, max-content)
    minmax(max-content, 140px)
    minmax(max-content, 160px);
  align-items: center;
  gap: 8px;

  @media (max-width: $responsive-xs-break) {
    grid-template-columns:
      minmax(50px, max-content)
      minmax(max-content, 140px)
      minmax(max-content, 140px);
  }
}
