.tooltip {
  width: fit-content;
  background-color: $color-base;
  border-radius: 5px;
  border: 1px solid $color-base;
  padding: 10px;

  .tooltipText {
    line-height: 1.3rem;
    color: $color-sono-sun;
  }
}
