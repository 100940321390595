.login {
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: $responsive-lg-break) {
    gap: 10px;
  }

  .sideImage {
    background-image: url(../../img/solar-range.jpg);
    width: 40%;
    background-size: cover;
    background-position: center;

    @media (max-width: $responsive-sm-break) {
      display: none;
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex-grow: 1;

    @media (max-width: $responsive-lg-break) {
      padding-left: 0;
    }

    .loginForm {
      flex-grow: 0;
      min-width: 300px;
      max-width: 70%;

      margin-top: auto;
      margin-bottom: auto;

      @media (max-width: $responsive-lg-break) {
        margin-inline: auto;
        min-width: 200px;
        max-width: 90%;
      }

      h1 {
        margin-bottom: 10px;
      }
      input {
        margin-top: 10px;
      }
    }

    p.error {
      min-height: 16px;
      line-height: 16px;
      margin-top: 10px;
      color: red;
    }
  }
}
