.myFleet {
  .aggregates {
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-bottom: $border-color;

    padding: 10px 24px;

    @media (max-height: $responsive-md-break) {
      padding: 5px 24px;
    }

    @media (max-width: $responsive-lg-break) {
      padding: 17px;
      gap: 12px;
    }
  }
}
