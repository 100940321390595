// Define the mixin for border style
@mixin border-style($size, $type, $color) {
  border: $size $type $color;
}

// Define the mixin for font style
@mixin font-style($size, $weight) {
  font-size: $size;
  font-weight: $weight;
}

// Define the mixin for transition
@mixin transition($property, $duration, $timing-function: ease, $delay: 0s) {
  transition: $property $duration $timing-function $delay;
}

// Define the mixin for width and height
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

// Define the mixin for flex container
@mixin flex-container($direction: row, $justify: flex-start, $align: stretch, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}
