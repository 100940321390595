.localeSwitcher {
  position: absolute;
  right: 48px;
  top: 18px;
  @include font-style($font-size-md, bold);
  display: flex;
  gap: $spacing-xs;

  @media (max-width: $responsive-lg-break) {
    position: fixed;
    z-index: 1000;
    color: $color-base;
    right: 24px;
    top: 22px;

    .menuOpen .localeSwitcher {
      filter: blur(3px);
    }
  }

  .language {
    opacity: 0.5;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}
