.networking {
  &.error {
    label {
      font-size: 80%;
      text-decoration: underline;
      cursor: pointer;
    }

    input#showDetails {
      display: none;
    }

    #showDetails:checked ~ .detail {
      display: block;
    }

    .detail {
      display: none;
      background-color: $color-modal-bg;
      font-size: 80%;
      padding: 8px;
      margin-top: 8px;
    }

    button {
      margin-top: 16px;
    }
  }

  .spinner {
    position: fixed;

    left: calc(50% - 32px);
    top: calc(50% - 64px);
    width: 64px;
    height: 64px;

    background-size: 64px 64px;
    filter: invert(1);

    animation: rotating 0.5s linear infinite;
  }
}
