.filter-dialog {
  h2 {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 14px;

  textarea {
    @include size(auto, 100px);
  }
}

.modeTitle {
  @include font-style($font-size-xl, normal);
}

li.modeItem {
  display: flex;
  align-items: center;

  input.modeRadio[type='checkbox'],
  input.modeRadio[type='radio'] {
    display: inline;
    margin-bottom: 0;
  }
  label {
    display: inline-block;
    font-weight: normal;
    cursor: pointer;
  }
  input.modeRadio {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    @include size(2px, 2px);
    transition: all 0.1s ease-out;
    background-color: $color-base;
    border: none;
    outline: 2px solid black;
    outline-offset: 3px;
    border-radius: 50%;
    padding: 4px;
    color: $color-base;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    z-index: 1000;
  }

  input.modeRadio:checked,
  input.modeRadio:active {
    background-color: $color-sono-sun;
    border: none;
    outline: 2px solid $color-sono-sun;
    outline-offset: 3px;
  }
  input.modeRadio:checked::before {
    @include size(2px, 2px);
    @include flex-container(row, center, center);
    @include font-style(25px, bold);
    font-family: $font-family;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input.modeRadio:checked::after {
    -webkit-animation: click-wave 0.35s;
    -moz-animation: click-wave 0.35s;
    animation: click-wave 0.35s;
    background: $color-sono-sun;
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input.modeRadio {
    border-radius: 50%;
  }
  input.modeRadio::after {
    border-radius: 50%;
  }

  @keyframes click-wave {
    0% {
      @include size(10px, 10px);
      opacity: 0.35;
    }
    100% {
      @include size(100px, 100px);
      opacity: 0;
    }
  }
}

li.modeItem:first-child {
  margin-bottom: 10px;
}
