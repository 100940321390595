*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $color-sono-day-90;
  margin: 0;
  padding: 0;
  font-family: $font-family;
  color: $color-sono-night;
  font-size: $font-size-md;
}

main {
  display: grid;
  grid-template-areas:
    'menu content'
    'menu footer';
  grid-template-columns: 260px 1fr;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  column-gap: 45px;

  @media (max-width: $responsive-lg-break) {
    grid-template-areas:
      'content'
      'footer';
    grid-template-columns: 1fr;
  }

  section {
    grid-area: content;
    width: 100%;
    padding: 48px 45px 0px 0px;

    @media (max-width: $responsive-xxl-break) {
      padding-right: 48px;
    }

    @media (max-width: $responsive-lg-break) {
      padding: 80px 24px 0px 24px;
    }

    @media (max-width: $responsive-xs-break) {
      padding: 80px 16px 0px 16px;
    }
  }
}

.blur {
  filter: blur(3px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

input:not([type='checkbox']):not([type='radio']),
textarea,
select {
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  color: $color-sono-night;
  background-color: $color-superlightgrey;
  border-radius: 10px;
  font-family: $font-family;
  @include border-style(2px, solid, $color-superlightgrey);
  line-height: 1.4;
  padding: 8px 10px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: $font-size-lg;
  text-align: start;
  cursor: pointer;

  @media (max-width: $responsive-sm-break) {
    font-size: $font-size-md;
    padding-left: 8px !important;
    padding-right: 3px !important;
  }
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../img/Chevron-down.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

select:hover,
select:focus {
  background-color: $color-outservice-grey;
  @include border-style(2px, solid, $color-outservice-grey);
  outline: none;
}

select > option {
  padding: 10px;
  cursor: pointer;
  font-family: $font-family;
  @include border-style(2px, solid, $color-superlightgrey);
}

textarea {
  overflow: auto;
  height: 100px;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.noContent {
  text-align: center;
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 6px 0;

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  td:first-child,
  th:first-child {
    text-align: start;
  }

  td,
  th {
    word-break: break-word;
    vertical-align: middle;
    text-align: center;

    .actionButton {
      margin-inline: auto;
      justify-content: center;
    }
  }

  td {
    padding: 8px 10px;
  }

  th {
    font-size: 14px;
    padding: 16px 10px;
    vertical-align: middle;

    @media (max-width: $responsive-md-break) {
      font-size: 12px;
    }

    .subLabel {
      display: block;
      font-weight: normal;
    }
  }

  td:not(:last-child) {
    @media (min-width: $responsive-sm-break) {
      border-right: 1px solid $color-outservice-grey;
    }
  }

  td:last-child,
  th:last-child {
    border-right: none;

    @media (max-width: $responsive-sm-break) {
      padding-left: 0;
    }
  }

  th.globalActions {
    position: relative;

    > div {
      display: flex;
    }

    .divider {
      flex-grow: 1;
    }

    ul {
      display: block;

      position: absolute;
      top: 15px;
      right: 12px;
      padding: 0;

      background-color: $color-sono-day-90;
      @include border-style(2px, solid, $color-sono-day-50);
      border-radius: $border-radius;

      li {
        list-style: none;

        button {
          font-size: $font-size-md;
          padding: 12px;
          border-radius: $border-radius;
        }

        button:hover {
          padding: 12px;
          background-color: $color-sono-night;
          color: $color-base;
        }
      }
    }
  }

  tbody {
    display: block;
    border-top: $border-color;

    @media (min-width: $responsive-sm-break) {
      overflow: hidden scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      padding-left: 10px;

      &.tbDashboard,
      &.tbDocuments {
        max-height: 70vh;
      }

      &.tbAdminFleets,
      &.tbVehicles,
      &.tbAdminUsers,
      &.tbUsers {
        max-height: 30vh;
      }
    }

    &:hover,
    &:focus {
      scrollbar-color: $color-sono-day-10 transparent;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $color-sono-day-90;
    }
  }

  tr.viewAll:last-child {
    background-color: transparent;
    td {
      border-top: 1px solid $color-sono-night;
      border-bottom: none;
      text-align: center;

      a {
        color: $color-sono-night;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }

      a:hover {
        color: $color-sono-sun;
      }
    }
  }
}

details {
  position: relative;
  cursor: pointer;

  & .list {
    width: 100%;
    background: $color-superlightgrey;
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    cursor: pointer;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;
    color: $color-sono-night;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    counter-reset: labels;

    @media (max-width: $responsive-lg-break) {
      max-height: 250px;
    }

    @media (max-width: $responsive-sm-break) {
      max-height: 240px;
    }

    li {
      padding: 16px 16px;
      border-bottom: 2px solid white;
      margin: 0;

      label {
        width: 100%;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        font-weight: normal;
        transform: scale(1);
        transition: all 0.2s ease-in-out;
        margin-bottom: 0;
      }

      li:focus,
      li:hover > label {
        transform: scale(1.02);
      }
    }
  }

  & .list:hover {
    scrollbar-width: thin;
    scrollbar-color: $color-sono-day-10 transparent;
  }

  li:focus,
  li:hover {
    background-color: $color-outservice-grey;
  }

  li:last-child {
    border-bottom: none;
  }
}

/* FAKE SELECT */

summary.radios {
  counter-reset: radios;
}

summary.radios:before {
  content: var(--selection);
}

input {
  margin: 0;
  cursor: pointer;
}

input[type='radio'] {
  counter-increment: radios;
  appearance: none;
  display: none;
}

input[type='radio']:checked {
  display: inline;
  --display: block;
}

input[type='radio']:after {
  content: attr(title);
  display: inline;
  font-size: $font-size-lg;
}

details[open] {
  z-index: 1;
}

summary {
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  color: $color-sono-night;
  background-color: $color-superlightgrey;
  border-radius: 10px;
  font-family: $font-family;
  @include border-style(2px, solid, $color-superlightgrey);
  line-height: 1.4;
  padding: 8px 10px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: $font-size-lg;
  text-align: start;
  cursor: pointer;

  @media (max-width: $responsive-sm-break) {
    font-size: $font-size-md;
    padding-left: 8px !important;
    padding-right: 3px !important;
  }
  background-image: url(../img/Chevron-down.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;

  @media (max-width: $responsive-sm-break) {
    background-position: right 4px center;
  }
}

details summary::-webkit-details-marker,
details summary::marker {
  display: none;
  content: '';
}

summary:focus,
summary:hover {
  background-color: $color-outservice-grey;
  border-color: $color-outservice-grey;
  outline: none;
}

button,
.button {
  @include font-style($font-size-md, 700);
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-align: center;
  text-transform: uppercase;
  background: none;
  @include border-style(2px, solid, $color-darkblue);
  color: $color-darkblue;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 10px;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  width: fit-content;

  &.small {
    padding: 6px 9px;
    min-width: 90px;
    border-radius: 9px;
  }

  @media (max-width: $responsive-lg-break) {
    padding-top: 7px;
    padding-bottom: 9px;
    white-space: break-spaces;
  }

  &:hover {
    color: $color-base;
    background-color: $color-sono-night;

    .icon {
      fill: $color-base;
    }
  }

  &:focus {
    outline: none;
    border-color: lighten($color-darkblue, 10);
  }

  &:active {
    border-color: rgba($color-grey, 0);
  }

  &[disabled],
  &.disabled {
    cursor: not-allowed;

    background: $color-superlightgrey;
    border-color: $color-superlightgrey;
    color: $color-grey;
  }

  &.solid {
    background: lighten($color-darkblue, 3);
    border-color: lighten($color-darkblue, 3);
    color: $color-base;

    &:hover {
      background: $color-blue;
      border-color: $color-blue;
      color: $color-base;
    }
  }

  &.textbutton {
    border: none;
    min-width: fit-content;

    @media (max-width: 400px) {
      font-size: 10px;
      padding: 5px 8px;
    }
  }
}

.actionButton {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: $spacing-xs;
  text-decoration: none;
  color: $color-sono-night;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 0;
  background-color: transparent;
  white-space: nowrap;

  img {
    height: 18px;
  }

  &a[href=''],
  &button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: transparent;
    color: $color-sono-sun;

    img {
      filter: brightness(0) invert(77%) sepia(46%) saturate(1914%) hue-rotate(357deg) brightness(95%) contrast(107%);
    }
  }
}

.actionButton.edit {
  @media (max-width: $responsive-sm-break) {
    display: none;
  }
}

.actionButton:focus,
.actionButton:hover {
  outline: none;
  border: none;
}

.buttons {
  @include flex-container(row, center, center, wrap);
  gap: $spacing-xs;
  margin-top: 10px;

  @media (max-width: $responsive-sm-break) {
    @include flex-container(row, center, center, wrap);
  }

  button {
    min-width: 120px !important;
  }
}

.back {
  cursor: pointer;
  margin: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;

  &:before {
    content: '❮';
    font-size: 24px;
  }
}

.operatingModeIcon {
  display: inline-block;
  @include size(24px, 24px);
  border-radius: 12px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);
}

.selector {
  position: relative;

  .arrow {
    cursor: pointer;
    font-size: 70%;
    margin-left: 9px;
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 3px;
    @extend .card;
    list-style: none;
    padding: 9px 12px;
    z-index: 1000;

    hr {
      border: none;
      border-bottom: 1px solid $color-grey;
    }

    li {
      font-size: 18px;

      a {
        padding: 6px;
        display: block;
        color: black;
        text-decoration: none;
      }
    }

    &.dropdown-menu li {
      cursor: pointer;
    }

    &.dropdown-menu li:hover {
      background-color: $color-sono-sun;
      color: white;
      border-radius: $border-radius;
    }
  }
}

@media (max-width: $responsive-sm-break) {
  .no-mobile {
    display: none !important;
    overflow: hidden;
  }
}
