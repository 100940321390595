.dashboardTile {
  @include flex-container(column, space-around, center);
  @include font-style(14px, bold);
  border-radius: 24px;
  padding: 12px;
  min-height: 100px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media (max-width: $responsive-sm-break) {
    border-radius: 14px;
  }

  img {
    @include size(72px, 72px);
  }

  .timestamp {
    @include font-style(14px, normal);

    @media (max-width: $responsive-xxl-break) {
      font-size: 11px;
    }

    @media (max-width: $responsive-md-break) {
      font-size: 8px;
    }
  }

  .number {
    font-size: 46px;
    @media (max-width: $responsive-xxl-break) {
      font-size: 40px;
    }

    @media (max-width: $responsive-xl-break) {
      font-size: 36px;
    }
  }

  &.night {
    grid-area: night;
    background-color: $color-sono-night;
    color: $color-sono-day-90;

    & .number {
      font-size: 52px;

      @media (max-width: $responsive-xxl-break) {
        font-size: 40px;
      }
      @media (max-width: $responsive-md-break) {
        font-size: 30px;
      }
    }
  }

  &.sun1 {
    grid-area: sun1;
  }
  &.sun2 {
    grid-area: sun2;
  }

  &.sun3 {
    grid-area: sun3;
  }

  &.sun4 {
    grid-area: sun4;
  }

  &.sun1,
  &.sun2,
  &.sun3,
  &.sun4 {
    background-color: $color-sono-sun;
    color: $color-sono-night;
  }

  @media (max-width: $responsive-lg-break) {
    padding: 6px;
    font-size: 9px;

    img {
      @include size(42px, 42px);
    }

    .number {
      font-size: 28px;
    }
  }

  @media (max-width: $responsive-sm-break) {
    font-size: 8px;

    .number {
      font-size: 22px;
    }
  }
}
