.popupContainer {
  position: relative;
  top: 13px;
  right: 10px;

  @include size(22px, 22px);
  cursor: pointer;

  @media (max-width: $responsive-lg-break) {
    @include size(20px, 20px);
    top: 10px;
    right: 12px;
  }

  @media (max-width: $responsive-sm-break) {
    @include size(18px, 18px);
    top: 10px;
    right: 16px;
  }
}

.popup,
.demodisclaimer {
  display: none;
  animation: fade_in 0.4s ease-in;

  position: absolute;
  z-index: 1000;
  top: 42px;
  right: -8px;

  background-color: $color-outservice-grey;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4.7px 16px rgba(0, 0, 0, 0.15);
  opacity: 1;

  @media (max-width: $responsive-md-break) {
    top: 38px;
    right: -10px;
  }

  h6 {
    font-size: 14px;

    @media (max-width: $responsive-md-break) {
      text-wrap: wrap;
      font-size: 12px;
    }
  }

  p {
    font-size: 12px;

    @media (max-width: $responsive-md-break) {
      text-wrap: wrap;
      font-size: 10px;
    }
  }
}

.popup::before,
.demodisclaimer::before {
  display: block;
  content: '';
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid $color-outservice-grey;
  top: -36px;
  right: 0;
}

.popup {
  width: 400px;

  @media (max-width: $responsive-lg-break) {
    width: 50vw;
  }
}

.demodisclaimer {
  text-align: start;
  width: 50vw;

  @media (max-width: $responsive-lg-break) {
    width: 89vw;
  }
}

.popupContainer:hover > .popup,
.popupContainer:hover > .demodisclaimer {
  display: block;
}

@keyframes fade_in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
