.loader {
  position: absolute;
  width: 65px;
  height: 40px;
  --g: radial-gradient(farthest-side, #0000 calc(95% - 3px), #{$color-sono-sun} calc(100% - 3px) 98%, #0000 101%)
    no-repeat;
  background: var(--g), var(--g), var(--g);
  background-size: 20px 20px;
  animation: l9 1s infinite alternate;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes l9 {
  0% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
  20% {
    background-position: 0 0, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0 100%, 50% 0, 100% 50%;
  }
  60% {
    background-position: 0 50%, 50% 100%, 100% 0;
  }
  80% {
    background-position: 0 50%, 50% 50%, 100% 100%;
  }
  100% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
}
