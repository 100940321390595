.dashboardContainer {
  @media (min-width: $responsive-xxl-break) {
    padding-bottom: 22px;
  }

  @media (max-width: $responsive-xxl-break) {
    padding-bottom: 10px;
  }

  & > .topBar {
    @include flex-container(row, space-between, center);
    margin-bottom: 15px;

    @media (max-width: $responsive-xs-break) {
      margin-bottom: 10px;
    }
  }
}

.dashboard {
  display: grid;
  row-gap: min(15px);

  h1 {
    background-color: $color-sono-night;
    color: $color-base;
    padding: 12px;
    font-size: 20px;
    margin-bottom: 12px;
    border-radius: $border-radius;
  }

  .details {
    padding: 24px;
    max-width: 1150px;

    @media (max-width: $responsive-lg-break) {
      padding: 8px;
    }

    .diagrams {
      @include flex-container(row, space-between, center, wrap);
      gap: 12px;

      .diagram {
        h2 {
          background-color: $color-sono-sun;
          font-size: 18px;
          padding: 6px;
          margin-bottom: 12px;
          align-self: stretch;
        }

        &.dailyEnergyEarnings {
          flex-grow: 1;
        }

        .dayPicker {
          text-align: right;
        }

        input {
          width: auto;
          margin-bottom: 12px;
          margin-left: 6px;
        }
      }
    }

    img.filter {
      @include size(16px, 16px);

      cursor: not-allowed;
      &.editable {
        cursor: pointer;
      }

      &.inactive {
        filter: grayscale(1) opacity(0.25);
      }
    }
  }
}
