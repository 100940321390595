.dashboardSummary {
  display: grid;
  grid-template-columns: minmax(40%, 420px) minmax(48%, 1200px);
  column-gap: 30px;

  @media (min-width: $responsive-xxl-break) {
    min-height: 36vh;
  }

  @media (max-width: $responsive-xxl-break) {
    justify-content: center;
  }

  @media (max-width: $responsive-xl-break) {
    justify-content: center;
  }

  @media (max-width: $responsive-sm-break) {
    grid-template-columns: minmax(300px, max-content);
  }

  @media (max-width: $responsive-xs-break) {
    row-gap: $spacing-sm;
  }

  .dashboardTiles {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'night sun1 sun2'
      'night sun3 sun4';

    @media (max-width: $responsive-md-break) {
      margin-bottom: $spacing-sm;
      gap: $spacing-sm;
    }
  }

  .dashboardChartContainer {
    height: 38vh;
    margin-left: -15px;

    @media (max-width: $responsive-xl-break) {
      height: 30vh;
    }

    @media (max-width: $responsive-lg-break) {
      height: 320px;
      margin-left: -20px;
    }

    @media (max-width: $responsive-sm-break) {
      height: 25vh;
    }
  }

  .tooltip {
    background-color: $color-base;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    div {
      @include flex-container(row, center, center);
      gap: $spacing-xs;
    }
  }
}
