.omTable {
  margin: 0;
  thead {
    overflow: hidden;
    padding: 0;
  }
  tr:last-child td {
    border-bottom: none;
  }

  .omTableBody {
    border: none;
    overflow: hidden;
  }
}

table th.omTableHead {
  border: none !important;
}

.color {
  margin-inline: auto;
  @media (max-width: $responsive-sm-break) {
    @include flex-container(row, center, center);
  }
  column-gap: 10px;
  .background {
    background-color: $color-sono-sun;
    margin-inline: auto;
  }

  div {
    @include size(12px, 12px);

    @media (max-width: $responsive-sm-break) {
      @include size(10px, 10px);
      margin-right: 8px;
    }
  }
}

.modeList {
  display: flex;
  justify-content: space-evenly;
  margin-top: -10px;
}

.modeText {
  font-size: $font-size-sm;
}
